import { CouponType } from "@/interfaces/types";
import { StoreCartStateItemType } from "@/interfaces/Store";

export function validateCouponData(couponData: CouponType, cartTotal: number, cartItems: StoreCartStateItemType[]) {
  const errors = [];

  // true if cart total amount is less than the minimum_amount variable in the coupon
  if (cartTotal < +couponData.minimum_amount) {
    errors.push(`El importe mínimo del pedido para usar este cupón es de ${(+couponData.minimum_amount).toLocaleString('es', {
      style: 'currency',
      currency: 'EUR'
    })}.`)
  }

  // true if maximum_amount variable is not zero and cart total amount is equal or greater than the maximum_amount variable in the coupon
  if (+couponData.maximum_amount && cartTotal >= +couponData.maximum_amount) {
    errors.push(`El importe máximo del pedido para usar este cupón es de ${(+couponData.maximum_amount).toLocaleString('es', {
      style: 'currency',
      currency: 'EUR'
    })}.`)
  }

  // true if discount_type is fixed_product and at least one product id from the cart is present in the coupon valid product_ids
  if (couponData.discount_type === "fixed_product") {
    const cartItemsIds = cartItems.map(item => item.id)
    const couponValidProductIds = couponData.product_ids;
    if (!couponValidProductIds.some(r => cartItemsIds.includes(r))) {
      errors.push("El cupón introducido no es elegible para ningún producto del carrito.")
    }
  }

  // return the validation data
  return {
    couponData: {
      code: couponData.code,
      amount: +couponData.amount,
      limitUsage: couponData.limit_usage_to_x_items
    },
    errors
  }

}

export function getOrderItemAddonsMetaData(item: StoreCartStateItemType) {
  const metaData: any = [];
  item.addons.forEach((addon) => {
    if (addon.type === "checkbox") {
      const options = addon.options as { selected: boolean; label: string }[];
      options.forEach((option: { selected: boolean; label: string }) => {
        if (option.selected) {
          metaData.push({
            "key": addon.label,
            "value": option.label
          });
        }
      })
    } else {
      metaData.push({
        "key": addon.label,
        "value": addon.selected
      });
    }
  });
  return metaData;
}

export function formatOrderItemMetaData(item: StoreCartStateItemType) {
  const metaData: any = [];
  item.addons.forEach((addon) => {
    if (addon.type === "checkbox") {
      const options = addon.options as { selected: boolean; label: string }[];
      options.forEach((option: { selected: boolean; label: string }) => {
        if (option.selected) {
          metaData.push({
            "name": addon.label,
            "value": option.label,
            "price": 0,
            "price_original": 0,
            "price_type": "fixed",
            "type_id": addon.id,
            "original_value": {
              "0": "ywapo_value_5",
              "2": "ywapo_value_5"
            },
            "original_index": 0,
            "sold_individually": "0",
            "calculate_quantity_sum": "0",
            "add_on_type": addon.type
          });
        }
      })
    } else {
      metaData.push({
        "name": addon.label,
        "value": addon.selected,
        "price": 0,
        "price_original": 0,
        "price_type": "fixed",
        "type_id": addon.id,
        "original_value": [
          "0"
        ],
        "original_index": 0,
        "sold_individually": "0",
        "calculate_quantity_sum": "0",
        "add_on_type": addon.type
      });
    }
  });
  return metaData;
}
