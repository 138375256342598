import {
  StoreCartStateType,
  StoreCartStateItemType,
  StoreCartStateCouponType,
  StoreCartStateDeliveryType,
  StoreCartMutationDeliveryAddressType,
} from "@/interfaces/Store";
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { AddressType, BillingAddressType, CartItemType } from "@/interfaces/types";
import { store } from '@/store';
import { initialUnencryptedStorage } from '@/store/storage';
import { paymentMethods } from "@/config/StaticStrings";
import { shippingMethods } from "@/config/StaticData";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import pick from "lodash/pick";
import remove from "lodash/remove";
import { sha1 } from "object-hash";
import { getStoreCoupon } from "@/services/Api";
import {
  validateCouponData,
  formatOrderItemMetaData,
  getOrderItemAddonsMetaData
} from "@/store/helpers/cart";

const initialCartState: StoreCartStateType = {
  cartItems: [],
  delivery: {
    method: "delivery",
    asap: true,
  },
  paymentMethod: "cc",
  coupon: {},
};

@Module({
  dynamic: true,
  store,
  name: 'cart',
  preserveState: Boolean(initialUnencryptedStorage['cart'])
})
class Cart extends VuexModule {
  cartItems: StoreCartStateItemType[] = initialCartState.cartItems
  delivery: StoreCartStateDeliveryType = initialCartState.delivery
  paymentMethod = initialCartState.paymentMethod
  note: string | undefined = initialCartState.note
  coupon: StoreCartStateCouponType = initialCartState.coupon

  get cartNumItems() {
    let total = 0;
    Object.keys(this.cartItems).forEach((item, index) => {
      total += this.cartItems[index].quantity;
    });
    return total;
  }

  get cartContents() {
    return this.cartItems;
  }

  get cartTotal() {
    let total = 0;
    Object.keys(this.cartItems).forEach((item, index) => {
      total += this.cartItems[index].price * this.cartItems[index].quantity;
    });
    return total.toFixed(2);
  }

  get deliveryData() {
    return this.delivery;
  }

  get deliveryMethod() {
    return this.delivery.method;
  }

  get deliveryShippingData() {
    return {...this.delivery.address, ...this.delivery.billing};
  }

  get customerNote() {
    return this.note;
  }

  get selectedPaymentMethod() {
    return paymentMethods[this.paymentMethod];
  }

  get couponData() {
    return this.coupon;
  }

  get shippingPostCode() {
    const customerData = this.context.rootGetters.customerData;
    return this.delivery.address?.postcode || customerData?.shipping.postcode || customerData?.billing.postcode;
  }

  get availableMethodTypes() {
    const customerData = this.context.rootGetters.customerData;
    const postCode = this.delivery.address?.postcode || customerData?.shipping.postcode || customerData?.billing.postcode || "ZZZZZZ"
    const shippingPostCode = shippingMethods[postCode] ? postCode : postCode.startsWith("47") ? "47XXX" : "XXXXX";
    return shippingMethods[shippingPostCode] ? shippingMethods[shippingPostCode].map((method) => method.type) : [];
  }

  get shippingLine() {
    const postCode = this.context.getters.shippingPostCode as string || "default";
    const shippingMethodType = this.delivery.method;
    const shippingMethodOptions = shippingMethods[postCode] || shippingMethods["default"]; // TODO: [BACK] Better handle unknown postcodes (rdelcampo)

    return shippingMethodOptions.find((method) => method.type === shippingMethodType) || shippingMethods["default"][0]
  }

  get orderTotals() {
    const shippingLine = this.context.getters.shippingLine;

    const subtotal = +this.context.getters.cartTotal;
    const discounts = this.coupon && this.coupon.amount && this.coupon.limitUsage ? this.coupon.amount * this.coupon.limitUsage : 0;
    const shipping = shippingLine && !shippingLine.hide_total ? shippingLine.total : 0;
    const total = subtotal + shipping - discounts;

    return {
      subtotal,
      discounts,
      shipping,
      total
    }
  }

  get orderMissingData() {
    const hasMinimumOrderAmount = this.orderTotals.total >= 15
    const hasShippingData = this.deliveryShippingData.phone;

    return !(hasMinimumOrderAmount && hasShippingData);
  }

  get orderObject() {
    const shippingLine = this.context.getters.shippingLine;

    return {
      /* eslint-disable @typescript-eslint/camelcase */
      billing: {...this.delivery.address, ...this.delivery.billing} as BillingAddressType,
      shipping: this.delivery.address as AddressType,
      currency: "EUR",
      line_items: this.cartItems.map((item: StoreCartStateItemType) => {
        return {
          product_id: item.id,
          variation_id: 0,
          quantity: item.quantity,
          meta_data: [
            {
              "key": "_ywapo_meta_data",
              "value": formatOrderItemMetaData(item),
            },
            {
              "key": "_ywapo_extra_info",
              "value": {"yith_wapo_sold_individually": false}
            }
          ].concat(getOrderItemAddonsMetaData(item))
        }
      }),
      payment_method: this.paymentMethod,
      payment_method_title: paymentMethods[this.paymentMethod].name,
      set_paid: false,
      shipping_lines: [{
        method_id: shippingLine.method_id,
        method_title: shippingLine.method_title,
        total: shippingLine.total.toFixed(2),
      }],
      customer_note: this.note,
      coupon_lines: this.coupon.code ? [{code: this.coupon.code}] : undefined,
      /* eslint-enable @typescript-eslint/camelcase */
    }
  }


  @Mutation
  addItemToCart(item: CartItemType) {
    const incomingItem = cloneDeep({...item});
    const incomingItemHash = sha1(incomingItem);
    const existingCartItem = this.cartItems.find((i) => isEqual(incomingItem, omit(i, ["hash", "quantity"])));

    if (existingCartItem) {
      // If there is an item in the cart with the same properties as the incoming item (except quantity),
      // add 1 to the quantity counter
      existingCartItem.quantity = existingCartItem.quantity + 1;
    } else {
      // If no item in cart exists with the same properties as the incoming item,
      // store the new item with a quantity of 1
      this.cartItems.push({
        ...incomingItem,
        hash: incomingItemHash,
        quantity: 1,
      });
    }
  }

  @Mutation
  setVariationQuantity(data: { hash: string; qty: number }) {
    const cartItem = this.cartItems.find((item) => item.hash === data.hash);
    if (cartItem) {
      cartItem.quantity = data.qty
    }
  }

  @Mutation
  deleteVariation(hash: string) {
    remove(this.cartItems, (item) => item.hash === hash);
  }

  @Mutation
  clearCart() {
    this.cartItems = [];
  }

  @Mutation
  resetOrderData() {
    this.cartItems = initialCartState.cartItems;
    this.delivery = initialCartState.delivery;
    this.paymentMethod = initialCartState.paymentMethod;
    this.coupon = initialCartState.coupon;
    this.note = initialCartState.note;
  }

  @Mutation
  setCustomerNote(note: string) {
    this.note = note;
  }

  @Mutation
  setPaymentMethod(method: string) {
    this.paymentMethod = method;
  }

  @Mutation
  setCouponData(couponData: StoreCartStateCouponType) {
    this.coupon = couponData;
  }

  @Mutation
  setDeliveryMethod(method: string) {
    this.delivery.method = method;
  }

  @Mutation
  setDeliveryAsap(asap: boolean) {
    this.delivery.asap = asap;
  }

  @Mutation
  setDeliverySchedule(schedule: string) {
    this.delivery.schedule = schedule;
  }

  @Mutation
  setDeliveryAddress(data: StoreCartMutationDeliveryAddressType) {
    this.delivery.address = {...omit(data, ['phone', 'email'])};
    this.delivery.billing = {...pick(data, ['phone', 'email'])};
  }


  @Action({rawError: true})
  addToCart(item: CartItemType) {
    return new Promise<void>((resolve) => {
      this.context.commit('addItemToCart', item);
      resolve();
    });
  }

  @Action({rawError: true})
  addCoupon(coupon: string) {
    return new Promise<void>((resolve, reject) => {
      let couponValidation: { couponData: StoreCartStateCouponType; errors: string[] } = {
        couponData: {},
        errors: []
      };

      getStoreCoupon(coupon).then((couponData) => {
        couponValidation = validateCouponData(couponData, +this.cartTotal, this.cartContents);

        // if no validationErrors in coupon validation
        if (couponValidation.errors.length) {
          reject({errors: couponValidation.errors})
        } else {
          this.setCouponData(couponValidation.couponData)
          resolve();
        }
      }).catch((err) => {
        if (err.response.data && err.response.data.code) {
          switch (err.response.data.code) {
            case 100:
              reject({errors: ["El código del cupón es inválido."]});
              break;
            case 101:
              reject({errors: ["El código del cupón ha expirado."]});
              break;
            case 102:
              reject({errors: ["El código del cupón ha alcanzado el límite de usos."]});
              break;
            default:
              reject({errors: ["Ha ocurrido un error en la validación del cupón."]});
              break;
          }
        } else {
          reject({errors: ["Ha ocurrido un error en la validación del cupón."]});
        }
      });
    });
  }

  @Action({commit: 'setCouponData'})
  clearCoupon() {
    return {};
  }

}

export default getModule(Cart);
